import { useEffect } from 'react'
import Seo from 'src/components/seo/Seo'
import { useStoreConfig } from 'src/hooks/useStoreConfig'

function Page() {
  const { signUpUrl } = useStoreConfig()

  useEffect(() => {
    window.location.href = signUpUrl
  }, [signUpUrl])

  return (
    <>
      <Seo />

      <div>loading...</div>
    </>
  )
}

export default Page
