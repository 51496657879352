import { Helmet } from 'react-helmet-async'

import type { SeoImage } from '../restructure/product/types/product'

type SeoProps = {
  title?: string
  description?: string
  canonical?: string
  language?: string
  noIndex?: boolean
  openGraph?: {
    type?: string
    title?: string
    description?: string
    url?: string
    images?: SeoImage[]
  }
}

const Seo = ({
  canonical,
  description,
  language = 'pt_BR',
  noIndex = true,
  title,
  openGraph,
}: SeoProps) => {
  return (
    <Helmet>
      {title && <title>{title}</title>}
      {canonical && <link rel="canonical" href={canonical} />}
      {description && <meta name="description" content={description} />}
      {language && <meta property="og:locale" content={language} />}
      {noIndex ? (
        <meta name="robots" content="noindex,nofollow" />
      ) : (
        <meta name="robots" content="index,follow" />
      )}
      {noIndex ? (
        <meta name="googlebot" content="noindex,nofollow" />
      ) : (
        <meta name="googlebot" content="index,follow" />
      )}

      {openGraph?.type && <meta property="og:type" content={openGraph?.type} />}
      {openGraph?.description && (
        <meta name="og:description" content={openGraph?.description} />
      )}
      {openGraph?.title && <meta name="og:title" content={openGraph?.title} />}
      {openGraph?.url && <meta name="og:url" content={openGraph?.url} />}
      {openGraph?.images?.map((img, idx) => (
        <meta name="og:image" key={`${img.url}-${idx}`} content={img.url} />
      ))}
    </Helmet>
  )
}

export default Seo
